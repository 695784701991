
import { computed, defineComponent, PropType } from 'vue';
import { GradedEvent, GradedManeuver, Maneuver } from '@/models';

import ScrollContainer from '@/components/ScrollContainerComponent.vue';
import IconComponent from '@/components/icon/IconComponent.vue';
import Icon from '@/components/icon/Icon';
import { GradeIconMap } from '@/models/GradeIconMap';

class ViewModel {
  constructor(
    public maneuver: Maneuver,
    public gradedManeuver?: GradedManeuver
  ) {}

  get icon(): Icon | undefined {
    const grade =
      this.gradedManeuver && this.gradedManeuver.grade
        ? this.gradedManeuver.grade
        : '';
    return GradeIconMap.get(grade);
  }
}
export default defineComponent({
  components: {
    IconComponent,
    ScrollContainer
  },
  emits: ['selectManeuver'],
  props: {
    event: {
      type: Object as PropType<GradedEvent>,
      required: true
    },
    activeManeuver: {
      type: Object as PropType<Maneuver>,
      required: false
    },
    maneuvers: {
      type: Array as PropType<Array<Maneuver>>,
      required: true
    },
    gradedManeuvers: {
      type: Array as PropType<Array<GradedManeuver>>,
      required: true
    }
  },
  setup(props, { emit }) {
    const viewModels = computed(() => {
      return props.maneuvers.map((maneuver: Maneuver) => {
        const gradedManeuver = props.gradedManeuvers.find((gradedManeuver) => {
          return gradedManeuver.name === maneuver.name;
        });
        return new ViewModel(maneuver, gradedManeuver);
      });
    });

    function handleManeuverClick(viewModel: ViewModel) {
      emit('selectManeuver', viewModel.maneuver);
    }

    return {
      handleManeuverClick,
      viewModels
    };
  }
});
