
import { computed, defineComponent, PropType, ref } from 'vue';
import { GradedManeuver, Maneuver } from '@/models';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { gradeItems } from '@/models/GradeItem';
import AlgoliaReasonSearchComponent from '@/components/search/AlgoliaSearchComponent.vue';
import AlgoliaCorrectionSearchComponent from '@/components/search/AlgoliaCorrectionSearchComponent.vue';
import algoliaSearch from 'algoliasearch/lite';
import config from '@/config';
import Reason from '@/models/Reason';
import { stringToCmsVariableName } from '@/util';
import ReasonSearchResult from '@/models/ReasonSearchResult';
import CorrectionSearchResult from '@/models/CorrectionSearchResult';
import Correction from '@/models/Correction';
import { debounce } from 'lodash';
import LoadingIndicatorComponent from '@/components/FormSavingIndicatorComponent.vue';

class ViewModel {
  constructor(
    public maneuver: Maneuver,
    public gradedManeuver?: GradedManeuver
  ) {}

  get hasReason() {
    return (
      this.gradedManeuver &&
      this.gradedManeuver.reason &&
      this.gradedManeuver.reason.display &&
      this.gradedManeuver.reason.display !== ''
    );
  }

  get hasCorrection() {
    return (
      this.gradedManeuver &&
      this.gradedManeuver.correction &&
      this.gradedManeuver.correction.display &&
      this.gradedManeuver.correction.display !== ''
    );
  }
}

export default defineComponent({
  components: {
    AlgoliaCorrectionSearchComponent,
    AlgoliaReasonSearchComponent,
    ButtonComponent,
    LoadingIndicatorComponent
  },
  emits: [
    'close',
    'commentChange',
    'correctionChange',
    'gradeChange',
    'nextManeuver',
    'reasonChange'
  ],
  props: {
    maneuver: {
      type: Object as PropType<Maneuver>,
      required: true
    },
    gradedManeuver: {
      type: Object as PropType<GradedManeuver>,
      required: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const defaultDropdownText = 'Select grade';
    const gradeItem = gradeItems.find(
      (gradeItem) => gradeItem.grade === props.gradedManeuver?.grade
    );
    const gradeModel = ref(gradeItem || defaultDropdownText);
    const commentsModel = ref(props.gradedManeuver?.comments);
    const algoliaIndexPrefix = config.algolia.index.prefix;
    const reasonIndex = `${algoliaIndexPrefix}_reason`;
    const correctionIndex = `${algoliaIndexPrefix}_correction`;
    const searchClient = algoliaSearch(
      config.algolia.appId,
      config.algolia.apiKey,
      {}
    );
    const debouncedCommentChange = debounce(handleCommentChange, 1000);
    const reasonCharacterCount = ref(0);
    const reasonMaxLength = 40;
    const correctionCharacterCount = ref(0);
    const correctionMaxLength = 40;

    const optionalFiltersManeuverKeys = computed(() => {
      return ['maneuverKey:' + props.maneuver.mif.formattedManeuver];
    });
    const optionalFiltersReasonKeys = computed(() => {
      if (
        props.gradedManeuver &&
        props.gradedManeuver.reason &&
        props.gradedManeuver.reason.key
      ) {
        return ['reasonKey:' + props.gradedManeuver.reason.key];
      }
      return [];
    });

    const viewModel = computed(() => {
      return new ViewModel(props.maneuver, props.gradedManeuver);
    });

    const dropdownItems = computed(() => {
      return gradeItems.filter((gradeItem) => gradeItem.grade);
    });

    const isGradeableManeuver = props.maneuver.mif.value != 'NONE';

    function handleOnReasonInputChange(value: string) {
      reasonCharacterCount.value = value.length;
    }

    function handleOnCorrectionInputChange(value: string) {
      correctionCharacterCount.value = value.length;
    }

    function handleGradeChange() {
      emit('gradeChange', gradeModel.value);
    }

    function handleCommentChange() {
      emit('commentChange', commentsModel.value);
    }

    function handleNextManeuver() {
      emit('nextManeuver');
    }

    function handleReasonInputEnterPressed(query: string) {
      reasonCharacterCount.value = query.length;
      emit(
        'reasonChange',
        Reason.fromJson({
          key: stringToCmsVariableName(query),
          display: query
        })
      );
    }

    function handleReasonChange(reason: ReasonSearchResult) {
      emit(
        'reasonChange',
        Reason.fromJson({
          key: reason.reasonKey,
          display: reason.reasonDisplay
        })
      );
    }

    function handleClearReason() {
      emit('reasonChange');
    }

    function handleCorrectionChange(
      correctionSearchResult: CorrectionSearchResult
    ) {
      const correction = Correction.fromJson({
        key: correctionSearchResult.correctionKey,
        display: correctionSearchResult.correctionDisplay
      });
      emit('correctionChange', correction);
    }

    function handleCorrectionInputEnterPressed(query: string) {
      const correction = Correction.fromJson({
        key: stringToCmsVariableName(query),
        display: query
      });
      emit('correctionChange', correction);
    }

    function handleClearCorrection() {
      emit('correctionChange');
    }

    function handleClose() {
      emit('close');
    }

    return {
      commentsModel,
      correctionIndex,
      debouncedCommentChange,
      defaultDropdownText,
      dropdownItems,
      reasonCharacterCount,
      reasonMaxLength,
      correctionCharacterCount,
      correctionMaxLength,
      gradeModel,
      isGradeableManeuver,
      handleClearCorrection,
      handleClearReason,
      handleClose,
      handleCorrectionChange,
      handleOnCorrectionInputChange,
      handleCorrectionInputEnterPressed,
      handleGradeChange,
      handleNextManeuver,
      handleReasonChange,
      handleOnReasonInputChange,
      handleReasonInputEnterPressed,
      optionalFiltersManeuverKeys,
      optionalFiltersReasonKeys,
      reasonIndex,
      searchClient,
      viewModel
    };
  }
});
