import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center justify-center w-full h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_component = _resolveComponent("icon-component")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      class: {
        'text-sm': _ctx.size == 'sm',
        'text-md': _ctx.size == 'md',
        'text-lg': _ctx.size == 'lg',
        'text-xl': _ctx.size == 'xl'
      },
      "data-testid": "zero-landing-message"
    }, _toDisplayString(_ctx.message), 3),
    _createVNode(_component_icon_component, {
      class: [{
        'w-24 h-24': _ctx.size == 'sm',
        'w-36 h-36': _ctx.size == 'md',
        'w-48 h-48': _ctx.size == 'lg',
        'w-60 h-60': _ctx.size == 'xl'
      }, "mt-2 opacity-25"],
      icon: _ctx.icon
    }, null, 8, ["class", "icon"])
  ]))
}