
import { computed, defineComponent, PropType, ref } from 'vue';
import StudentGradeManeuverCollectionComponent from '@/components/student/grade/maneuver/StudentGradeManeuverCollectionComponent.vue';
import StudentGradeEventComponent from '@/components/student/grade/event/StudentGradeEventComponent.vue';
import StudentGradeManeuverComponent from '@/components/student/grade/maneuver/StudentGradeManeuverComponent.vue';
import StudentGradeManeuverComponentZero from '@/components/student/grade/maneuver/StudentGradeManeuverComponentZero.vue';
import ToastComponent from '@/components/ToastComponent.vue';
import { useStore } from 'vuex';
import ZeroLandingComponent from '@/components/ZeroLandingComponent.vue';
import {
  CreateGradedManeuverViewModel,
  Event,
  GradedEvent,
  GradedManeuver,
  GradeItem,
  Maneuver,
  Student
} from '../../models';
import Reason from '../../models/Reason';
import Correction from '../../models/Correction';
export default defineComponent({
  components: {
    StudentGradeEventComponent,
    StudentGradeManeuverCollectionComponent,
    StudentGradeManeuverComponent,
    StudentGradeManeuverComponentZero,
    ToastComponent,
    ZeroLandingComponent
  },
  props: {
    student: {
      type: Object as PropType<Student>,
      required: true
    },
    gradedEvent: {
      type: Object as PropType<GradedEvent>,
      required: true
    },
    event: {
      type: Object as PropType<Event>,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const zeroLandingMessage = ref(
      `Event ${props.gradedEvent.name} has been deleted`
    );
    const store = useStore();
    const isSavingEvent = computed(() => {
      return store.getters['studentModule/isSavingEvent'];
    });
    const isSavingManeuver = computed(
      () => store.getters['studentModule/isSavingManeuver']
    );
    const activeManeuver = ref();
    const activeGradedManeuver = computed(() => {
      if (!activeManeuver.value) return;
      return props.gradedEvent.maneuvers.find(
        (maneuver: GradedManeuver) =>
          maneuver.name === activeManeuver.value.name
      );
    });

    const currentManeuver = computed(() => {
      let maneuver;
      if (activeGradedManeuver.value) {
        maneuver = GradedManeuver.fromJson(activeGradedManeuver.value);
      } else {
        maneuver = CreateGradedManeuverViewModel.fromJson({
          ...activeManeuver.value,
          eventId: props.gradedEvent.id
        });
      }
      return maneuver;
    });

    function onEventGradeChange(gradeItem: GradeItem) {
      const event = GradedEvent.toJson(props.gradedEvent);
      event.overallGrade = gradeItem.grade || '';
      updateEvent(event);
    }

    function onEventCommentChange(comments: string) {
      const event = GradedEvent.toJson(props.gradedEvent);
      event.overallComments = comments;
      updateEvent(event);
    }

    function updateEvent(event: GradedEvent) {
      store.dispatch('studentModule/updateEventWithoutFetchingFullyQualified', {
        studentId: props.student.id,
        event
      });
    }

    function updateManeuver(
      maneuver: GradedManeuver | CreateGradedManeuverViewModel
    ) {
      const payload = {
        studentId: props.student.id,
        eventId: props.gradedEvent.id,
        maneuver
      };
      if (maneuver.type === CreateGradedManeuverViewModel._type) {
        store.dispatch('studentModule/createEventManeuver', payload);
      } else {
        store.dispatch('studentModule/updateEventManeuver', payload);
      }
    }

    function onManeuverGradeChange(gradeItem: GradeItem) {
      currentManeuver.value.grade = gradeItem.grade || '';
      updateManeuver(currentManeuver.value);
    }

    function onManeuverCommentChange(comments: string) {
      currentManeuver.value.comments = comments;
      updateManeuver(currentManeuver.value);
    }

    function onManeuverReasonChange(reason?: Reason) {
      currentManeuver.value.reason =
        reason || Reason.fromJson({ key: '', display: '' });
      updateManeuver(currentManeuver.value);
    }

    function onManeuverCorrectionChange(correction?: Correction) {
      currentManeuver.value.correction =
        correction || Correction.fromJson({ key: '', display: '' });
      updateManeuver(currentManeuver.value);
    }

    function onClose() {
      activeManeuver.value = undefined;
    }

    function onManeuverSelection(maneuver: Maneuver) {
      if (activeManeuver.value && activeManeuver.value.name === maneuver.name) {
        activeManeuver.value = undefined;
      } else {
        activeManeuver.value = maneuver;
      }
    }

    function handleNextManeuver() {
      const activeManeuverIndex = props.event.maneuvers.findIndex(
        (maneuver: Maneuver) => {
          return maneuver.name === activeManeuver.value.name;
        }
      );
      let nextIndex = activeManeuverIndex + 1;
      if (nextIndex === props.event.maneuvers.length) {
        nextIndex = 0;
      }
      activeManeuver.value = props.event.maneuvers[nextIndex];
    }

    return {
      activeGradedManeuver,
      activeManeuver,
      zeroLandingMessage,
      onClose,
      onEventCommentChange,
      onEventGradeChange,
      onManeuverCommentChange,
      onManeuverCorrectionChange,
      onManeuverGradeChange,
      onManeuverReasonChange,
      onManeuverSelection,
      handleNextManeuver,
      isSavingManeuver,
      isSavingEvent
    };
  }
});
