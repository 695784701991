
import { defineComponent } from 'vue';
import Icon from '@/components/icon/Icon';
import IconComponent from '@/components/icon/IconComponent.vue';

export default defineComponent({
  components: {
    IconComponent
  },
  props: {
    message: {
      type: String,
      default: 'No data available'
    },
    icon: {
      type: Number,
      default: Icon.WINGS
    },
    size: {
      type: String,
      default: 'sm'
    }
  }
});
