import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "h-full" }
const _hoisted_2 = {
  key: 0,
  class: "flex h-full"
}
const _hoisted_3 = {
  key: 1,
  class: "grid h-full grid-cols-3 grid-rows-1"
}
const _hoisted_4 = { class: "bg-primary-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_zero_landing_component = _resolveComponent("zero-landing-component")
  const _component_student_grade_event_component = _resolveComponent("student-grade-event-component")
  const _component_student_grade_maneuver_collection_component = _resolveComponent("student-grade-maneuver-collection-component")
  const _component_student_grade_maneuver_component = _resolveComponent("student-grade-maneuver-component")
  const _component_student_grade_maneuver_component_zero = _resolveComponent("student-grade-maneuver-component-zero")
  const _component_toast_component = _resolveComponent("toast-component")

  return (_openBlock(), _createBlock("section", _hoisted_1, [
    (_ctx.gradedEvent.deleted)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode(_component_zero_landing_component, {
            message: _ctx.zeroLandingMessage,
            size: 'xl'
          }, null, 8, ["message"])
        ]))
      : (_openBlock(), _createBlock("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_student_grade_event_component, {
              event: _ctx.gradedEvent,
              readonly: _ctx.readonly,
              isLoading: _ctx.isSavingEvent,
              onGradeChange: _ctx.onEventGradeChange,
              onCommentChange: _ctx.onEventCommentChange
            }, null, 8, ["event", "readonly", "isLoading", "onGradeChange", "onCommentChange"])
          ]),
          _createVNode(_component_student_grade_maneuver_collection_component, {
            event: _ctx.gradedEvent,
            maneuvers: _ctx.event.maneuvers,
            gradedManeuvers: _ctx.gradedEvent.maneuvers,
            activeManeuver: _ctx.activeManeuver,
            onSelectManeuver: _ctx.onManeuverSelection
          }, null, 8, ["event", "maneuvers", "gradedManeuvers", "activeManeuver", "onSelectManeuver"]),
          (_ctx.activeManeuver)
            ? (_openBlock(), _createBlock(_component_student_grade_maneuver_component, {
                class: "bg-primary-dark",
                readonly: _ctx.readonly,
                key: _ctx.activeManeuver.name,
                maneuver: _ctx.activeManeuver,
                gradedManeuver: _ctx.activeGradedManeuver,
                isLoading: _ctx.isSavingManeuver,
                onGradeChange: _ctx.onManeuverGradeChange,
                onCommentChange: _ctx.onManeuverCommentChange,
                onReasonChange: _ctx.onManeuverReasonChange,
                onCorrectionChange: _ctx.onManeuverCorrectionChange,
                onNextManeuver: _ctx.handleNextManeuver,
                onClose: _ctx.onClose
              }, null, 8, ["readonly", "maneuver", "gradedManeuver", "isLoading", "onGradeChange", "onCommentChange", "onReasonChange", "onCorrectionChange", "onNextManeuver", "onClose"]))
            : (_openBlock(), _createBlock(_component_student_grade_maneuver_component_zero, {
                key: 1,
                class: "bg-primary-dark"
              }))
        ])),
    _createVNode(_component_toast_component, { ref: "toastComponent" }, null, 512)
  ]))
}