
import { computed, defineComponent, PropType, ref } from 'vue';
import { GradedEvent } from '@/models';
import IconComponent from '@/components/icon/IconComponent.vue';
import Icon from '@/components/icon/Icon';
import { GradeIconMap } from '@/models/GradeIconMap';
import { gradeItems } from '@/models/GradeItem';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Modal from '@/components/modal/ModalComponent.vue';
import { debounce } from 'lodash';
import LoadingIndicatorComponent from '@/components/FormSavingIndicatorComponent.vue';

class ViewModel {
  constructor(private event: GradedEvent) {}

  get icon(): Icon | undefined {
    return GradeIconMap.get(this.event.overallGrade);
  }
}

export default defineComponent({
  components: {
    ButtonComponent,
    IconComponent,
    LoadingIndicatorComponent,
    Modal
  },
  emits: ['commentChange', 'gradeChange'],
  props: {
    event: {
      type: Object as PropType<GradedEvent>,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const defaultDropdownText = 'Select grade';
    const gradeItem = gradeItems.find(
      (gradeItem) => gradeItem.grade === props.event?.overallGrade
    );
    const gradeModel = ref(gradeItem || defaultDropdownText);
    const commentModel = ref(props.event.overallComments);
    const showDescription = ref(false);
    const debouncedCommentChange = debounce(handleCommentChange, 1000);

    const viewModel = computed(() => {
      return new ViewModel(props.event);
    });

    const dropdownItems = computed(() => {
      return gradeItems.filter((gradeItem) => gradeItem.grade);
    });

    function handleGradeChange() {
      emit('gradeChange', gradeModel.value);
    }

    function handleCommentChange() {
      emit('commentChange', commentModel.value);
    }

    return {
      commentModel,
      debouncedCommentChange,
      defaultDropdownText,
      dropdownItems,
      gradeModel,
      handleGradeChange,
      Icon,
      showDescription,
      viewModel
    };
  }
});
